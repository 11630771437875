import React from 'react'

import styled from 'styled-components';
import {device} from '../styles/Breakpoints'





class Cursor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
      if (window.matchMedia("(min-width: 768px)").matches) {
        if (this.props.staticText) {
          this.CustomCursor();
        }
        if (this.props.cursorSparkle) {
          this.fairyDustCursor(this.props.cursorSparkle);
        }
    }
      
        
      }

    CustomCursor = () => {
        var checkForCountElement = setInterval( () => {
    
            if (document.getElementById('custom-cursor')) {
                clearInterval(checkForCountElement);
                let circle = document.getElementById('custom-cursor');
                const onMouseMoveCursor = (e) =>{
                    // const yPos = parseInt(e.pageY) + 10;
                    // circle.style.left = e.pageX + 'px';
                    // circle.style.top = yPos + 'px';
                    // var parentElement = e.target.getBoundingClientRect();
                    var x = e.clientX; //x position within the element.
                    var y = e.clientY;  //y position within the element.
                    circle.style.left = x + 'px';
                    circle.style.top = y + 'px';
                }
                return (
                    document.addEventListener('mousemove', onMouseMoveCursor),
                    document.addEventListener('onscroll', onMouseMoveCursor)
                    );
            }
            else {
              console.log('waiting to load...')
            }
        }, 100);
        
    }

    fairyDustCursor = (cursorSparkle) => {
        // console.log('sparkle cursor set')
        var possibleColors = ["#D61C59", "#E7D84B", "#1B8798"]
        var width = window.innerWidth;
        var height = window.innerHeight;
        var cursor = {x: width/2, y: width/2};
        var particles = [];
        
        function init() {
          bindEvents();
          loop();
        }
        
        // Bind events that are needed
        function bindEvents() {
          document.addEventListener('mousemove', onMouseMove);
          document.addEventListener('touchmove', onTouchMove);
          document.addEventListener('touchstart', onTouchMove);
          
          window.addEventListener('resize', onWindowResize);
        }
        
        function onWindowResize(e) {
          width = window.innerWidth;
          height = window.innerHeight;
        }
        
        function onTouchMove(e) {
          if( e.touches.length > 0 ) {
            for( var i = 0; i < e.touches.length; i++ ) {
              addParticle( e.touches[i].clientX, e.touches[i].clientY, possibleColors[Math.floor(Math.random()*possibleColors.length)]);
            }
          }
        }
        
        function onMouseMove(e) {    
          cursor.x = e.clientX;
          cursor.y = e.clientY;
          
          addParticle( cursor.x, cursor.y, possibleColors[Math.floor(Math.random()*possibleColors.length)]);
        }
        
        function addParticle(x, y, color) {
          var particle = new Particle();
          particle.init(x, y, color);
          particles.push(particle);
        }
        
        function updateParticles() {
          
          // Updated
          for( var i = 0; i < particles.length; i++ ) {
            particles[i].update();
          }
          
          // Remove dead particles
          for( var i = particles.length -1; i >= 0; i-- ) {
            if( particles[i].lifeSpan < 0 ) {
              particles[i].die();
              particles.splice(i, 1);
            }
          }
          
        }
        
        function loop() {
          requestAnimationFrame(loop);
          updateParticles();
        }
        
        /**
         * Particles
         */
        
        function Particle() {
      
          this.character = cursorSparkle;
          this.lifeSpan = 120; //ms
          this.initialStyles ={
            "position": "absolute",
            "display": "block",
            "pointerEvents": "none",
            "z-index": "10000000",
            "fontSize": "24px",
            "will-change": "transform"
          };
      
          // Init, and set properties
          this.init = function(x, y, color) {
      
            this.velocity = {
              x:  (Math.random() < 0.5 ? -1 : 1) * (Math.random() / 2),
              y: 1
            };
            
            this.position = {x: x - 10, y: y + 20};
            this.initialStyles.color = color;
      
            this.element = document.createElement('span');
            this.element.innerHTML = this.character;
            applyProperties(this.element, this.initialStyles);
            this.update();
            
            document.querySelector('.sparkle-cursor-container').appendChild(this.element);
          };
          
          this.update = function() {
            this.position.x += this.velocity.x;
            this.position.y += this.velocity.y;
            this.lifeSpan--;
            
            this.element.style.transform = "translate3d(" + this.position.x + "px," + this.position.y + "px, 0) scale(" + (this.lifeSpan / 120) + ")";
          }
          
          this.die = function() {
            this.element.parentNode.removeChild(this.element);
          }
          
        }
        
        /**
         * Utils
         */
        
        // Applies css `properties` to an element.
        function applyProperties( target, properties ) {
          for( var key in properties ) {
            target.style[ key ] = properties[ key ];
          }
        }
        
        init();
    }
    
    

    render() {

        const staticText = this.props.staticText;
        const staticTextColor = this.props.staticTextColor;

        const CursorStyles = styled.div`
        position: fixed;
        top: 0;
        left: 0;
        pointer-events: none;
        width: 100vw;
        height: 100vh;
        z-index: 2000;
        .inner-container {
          width: 100%;
          height: 100%;
          position: relative;
          top: 0;
          left: 0;
        }
        @media ${device.$small} {
            display: none;
        }
        .sparkle-cursor-container-container {
            position: fixed;
            width: 100vw;
            height: 100vh;
            top: 0;
            left: 0;
            pointer-events: none;
            z-index: 10000;
        }
        .sparkle-cursor-container {
            width: 100%;
            height: 100%;
            position: relative;
        }
        #custom-cursor {
            position:absolute;
            width: fit-content;
            white-space: nowrap;
            z-index: 100;
            font-weight: 400;
            font-family: serif;
            &:after {
                position: absolute;
                content: '${staticText}';
                color: ${staticTextColor};
                display: block;
                margin-left: 20px;
                width: auto;
            }
        }
`
        
        return (
            <CursorStyles className="cursor-container">
                <div className="inner-container">
                  <div className="sparkle-cursor-container-container">
                      <div className="sparkle-cursor-container"></div>
                  </div>
                  <div id="custom-cursor"></div>
                </div>
            </CursorStyles>
        )
    }
}

export default Cursor
