import React from 'react'

import { FaInstagram } from 'react-icons/fa';
import { FiMail } from 'react-icons/fi';
import { RiVimeoLine } from 'react-icons/ri';

import styled from 'styled-components';
import {device} from '../styles/Breakpoints'

const FooterStyles = styled.footer`
        width: 100vw;
        padding: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 400;
        position: relative;
        z-index: 10;
        background-color: #fff;
        @media ${device.$small} {
            flex-direction: column;
        }
        .back-to-top {
            cursor: pointer;
            @media ${device.$small} {
                margin-bottom: 24px;
            }
        }
        .copyright {
            h1 {
                display: inline-block;
                margin: 0;
                font-size: inherit;
                font-weight: inherit;
            }
            
            @media ${device.$small} {
                margin-bottom: 24px;
            }
        }
        .icons {
            .icon {
                &:not(:last-child) {
                    margin-right: 24px;
                }
            }
            svg {
                width: 24px;
                height: 24px;
            }
        }
`


//Scroll To top

let intervalId = 0; // Need to cancel the scrolling when we're at the top of the page

function scrollStep() {
    // Check if we're at the top already. If so, stop scrolling by clearing the interval
    if (window.pageYOffset === 0) {
        clearInterval(intervalId);
    }
    window.scroll(0, window.pageYOffset - 50);
}

function scrollToTop() {
    // Call the function scrollStep() every 6 millisecons
    intervalId = setInterval(scrollStep, 6);
}



export default function Footer(props) {
    // console.log('Footer Props: ', props)
    const data = props.siteSettings;
    return (
        <FooterStyles>
            <div className="back-to-top" onClick={scrollToTop}>Back to Top</div>
            <div className="copyright">&copy; <h1>Imogen Wilson</h1> - {(new Date().getFullYear())}</div>
            <div className="icons">
                { data.instagram_url && 
                    <a href={data.instagram_url} target="_blank" rel="noopener" className="icon">
                        <FaInstagram />
                        <span className="visually-hidden">Instagram</span>
                    </a>
                }
                { data.footer_mailto && 
                    <a href={"mailto:" + data.footer_mailto} target="_blank" rel="noopener" className="icon">
                        <FiMail />
                        <span className="visually-hidden">Email</span>
                    </a>
                }
                { data.vimeo_url && 
                    <a href={data.vimeo_url} target="_blank" rel="noopener" className="icon">
                        <RiVimeoLine />
                        <span className="visually-hidden">Vimeo</span>
                    </a>
                }
                
            </div>
        </FooterStyles>
    )
}
