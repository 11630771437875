import React from 'react';
import styled from 'styled-components';
import Header from './Header';
import SideMenu from './SideMenu';

const HeaderAndMenuStyles = styled.div`
    top: 0;
    width: 100vw;
    margin-left: auto;
    margin-right: auto;
    z-index: 15;
    pointer-events: none;
`



export default function HeaderAndMenu(props) {
    // console.log('HEADER & MENU DATA', props)
    const siteSettings = props.siteSettings;
    const roleData = props.roleData;

    let positionStyle;

    switch (props.page) {
        case 'info':
            positionStyle = {
                position: 'absolute'
            }
            break;
    
        default:
            positionStyle = {
                position: 'sticky'
            }
            break;
    }

    return (
        <HeaderAndMenuStyles style={positionStyle}>
            <Header logo={siteSettings.logo} marqueeContent={siteSettings.marquee_text}/>
            <SideMenu roles={roleData} siteSettings={siteSettings} />
        </HeaderAndMenuStyles>
    )
}
