import React from 'react'
import Img from 'gatsby-image';
import { Link } from 'gatsby';

import styled from 'styled-components';
import {device} from '../styles/Breakpoints';




function HeaderLogo(props) {
    // console.log('THESE PROPS', props)
    if (props.logoData.asset.extension == 'svg') {
        return <div className="header__logo">
            <img src={props.logoData.asset.url} alt={'logo'}/>
        </div>
        
    }
    else {
        return <div className="header__logo">
            <Img fluid={props.logoData.asset.fluid} alt={'logo'}/>
        </div>
    }
    
}

const HeaderStyles = styled.header`
    pointer-events: none;
    /* height: auto; */
    padding-bottom: var(--gutter);
    z-index: 100;
    position: absolute;
    top: 0;
    left: 300px;
    width: calc(100vw - 600px);
    display: flex;
    justify-content: center;
    align-items: center;
    a {
        height: 100%;
    }
    @media ${device.$small} {
        width: calc(100vw - 96px);
        left: 48px;
        padding: 16px;
        min-height: initial;
    }
    &.no-marquee {
        height: var(--header-height);
        padding-top: var(--gutter);
        .gatsby-image-wrapper {
            width: 172px;
        }
        @media ${device.$small} {
            height: var(--header-height-mobile);
            top: initial;
            padding-top: 16px;
            .gatsby-image-wrapper {
                width: 100%;
            }
        }
    }
    &.with-marquee {
        height: var(--header-height-marquee);
        padding-top: calc(var(--marquee-height) + var(--gutter));
        .gatsby-image-wrapper {
            width: 172px;
        }
        @media ${device.$small} {
            height: var(--header-height-mobile-marquee);
            top: var(--marquee-height);
            padding-top: 16px;
            .gatsby-image-wrapper {
                width: var(--header-height-marquee);
            }
        }
    }
    .header__logo {
        height: 100%;
        pointer-events: all;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            height: 100%;
        }
        @media ${device.$small} {
            height: auto;
            width: 172px;
        }
    }
`



export default function Header(props) {

    const HeaderStyleClassName = props.marqueeContent ? 'with-marquee' : 'no-marquee';
    
    return (
        <HeaderStyles className={ HeaderStyleClassName }>
            <Link to="/" className="menu-item">
                <HeaderLogo logoData={props.logo} />
            </Link>
            
        </HeaderStyles>
    )
}