import React from 'react'

import { Link } from 'gatsby';
import styled from 'styled-components';
import AnimateHeight from 'react-animate-height';
import emoji from 'react-easy-emoji';

import {device} from '../styles/Breakpoints'
import { LightenDarkenColor } from '../utils/helpers'


const MenuStyles = styled.nav`
        position: absolute;
        top: 0;
        right: 0;
        pointer-events: all;
        @media ${device.$small} {
            width: 100vw;
            height: 80px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 16px;
        }
        .sidebar {
            position: absolute;
            right: 0;
            transition: .2s all ease-in-out;
            
            padding: 48px;
            @media ${device.$small} {
                display: none;
            }
            &__inactive {
                
                height: 90vh;
                background: transparent;
                padding-left: 36px; // 24px plus half menu-button width
                padding-right: 36px; // 24px plus half menu-button width
                .menu-wrapper {
                    display: none;
                }
                .menu-button {
                    left: 24px;
                    z-index: 500;
                }
                .menu-emojis {
                    display: none;
                    /* position: absolute;
                    width: 72px;
                    left: 0;
                    bottom: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding-bottom: 24px;
                    .menu-emoji {
                        margin-top: 16px;
                    } */
                }
            }
            &__active {
                height: 100vh;
                width: 300px;
                transform: translateX(0);
                border-left: none;
                z-index: 110;
                .menu-button {
                    left: calc(100% - 48px);
                    height: 24px;
                    background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24.5186 1L0.598389 25' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M0.598389 1L24.5186 25' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                }
                .menu-wrapper {
                    display: flex;
                }
                .menu-emojis {
                    position: relative;
                    display: flex;
                    justify-content: flex-end;
                    left: initial;
                    .menu-emoji {
                        margin-left: 12px;
                    }
                }
            }
            .menu-wrapper {
                padding-top: 72px;
                
                flex-direction: column;
                height: 100%;
                justify-content: space-between;
                text-align: right;
                    p {
                        margin: 0;
                    }
                    a {
                        display: inline-block;
                        &:not(.instagram-icon) {
                            &:hover {
                                background: -webkit-linear-gradient(1turn, ${props => props.siteSettings.hover_gradient_one.hex}, ${props => props.siteSettings.hover_gradient_two.hex}, ${props => props.siteSettings.hover_gradient_three.hex});
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                            }
                        }
                    }
                .menu-item {
                    font-size: 24px;
                    line-height: 35px;
                    font-weight: 900;
                    text-transform: uppercase;
                    margin-bottom: 6px;
                    &.roles {
                        cursor: pointer;
                        margin-bottom: 12px;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                    }
                    &__sub-item {
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 35px;
                        margin-bottom: 6px;
                    }
                    &.mimi-casting {
                        margin-top: 24px;
                    }
                    &.shop {
                        display: block;
                    }
                }
            }
        }

        .instagram-icon {
            height: 38px;
            width: 38px;
            margin-left: auto;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url("data:image/svg+xml,%3Csvg width='39' height='39' viewBox='0 0 39 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28.6238 1H10.2079C5.12253 1 1 5.14137 1 10.25V28.75C1 33.8586 5.12253 38 10.2079 38H28.6238C33.7092 38 37.8318 33.8586 37.8318 28.75V10.25C37.8318 5.14137 33.7092 1 28.6238 1Z' stroke='%23493838' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M26.7821 18.3346C27.0094 19.8742 26.7476 21.4467 26.034 22.8282C25.3204 24.2098 24.1913 25.3302 22.8073 26.0299C21.4233 26.7297 19.8549 26.9733 18.3252 26.726C16.7955 26.4787 15.3823 25.7532 14.2867 24.6526C13.1911 23.552 12.4689 22.1324 12.2227 20.5956C11.9766 19.0589 12.219 17.4834 12.9156 16.0931C13.6122 14.7027 14.7275 13.5685 16.1028 12.8516C17.4781 12.1347 19.0434 11.8718 20.576 12.1001C22.1394 12.333 23.5867 13.0648 24.7043 14.1874C25.8218 15.3101 26.5503 16.7641 26.7821 18.3346Z' stroke='%23493838' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M29.5447 9.32495H29.564' stroke='%23493838' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        }

        .menu-emojis {
            margin-top: 12px;
            font-size: 36px;
            @media ${device.$small} {
                margin-top: 24px;
                font-size: 42px;
            }   
            .menu-emoji {
                @media ${device.$small} {
                    margin-top: 12px;
                    &:not(:last-child) {
                        margin-right: 24px;
                    }
                }
            }
        }
        
        .menu-button {
            cursor: pointer;
            position: absolute;
            top: 48px;
            width: 24px;
            height: 18px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-menu'%3E%3Cline x1='3' y1='12' x2='21' y2='12'%3E%3C/line%3E%3Cline x1='3' y1='6' x2='21' y2='6'%3E%3C/line%3E%3Cline x1='3' y1='18' x2='21' y2='18'%3E%3C/line%3E%3C/svg%3E");
            
        }

        .mobile-menu-button {
            width: 24px;
            height: 18px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-menu'%3E%3Cline x1='3' y1='12' x2='21' y2='12'%3E%3C/line%3E%3Cline x1='3' y1='6' x2='21' y2='6'%3E%3C/line%3E%3Cline x1='3' y1='18' x2='21' y2='18'%3E%3C/line%3E%3C/svg%3E");
            display: none;
            @media ${device.$small} {
                display: block;
            }
        }

        .mobile-menu {
            display: none;
            transform: translateX(100vw);
            position: fixed;
            z-index: 500;
            width: 100vw;
            height: 100vh;
            top: 0;
            left: 0;
            background: #fff;
            
            transition: .2s all ease-in-out;
            @media ${device.$small} {
                display: block;
            }
            &__active {
                transform: translateX(0);
            }
            .mobile-menu--holder {
                display: flex;
                flex-direction: column;
                align-items: center;   
                height: 100%; 
                &__active {
                    display: flex;
                    flex-direction: column;
                    align-items: center; 
                    height: 100%;
                    overflow-y: scroll;
                    padding: 48px 0;
                }
            }
            .close-mobile-menu {
                position: absolute;
                top: 24px;
                right: 24px;
                width: 24px;
                height: 24px;
                background-size: contain;
                background-position: center;
                background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24.9203 1L1 25' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1 1L24.9203 25' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                @media ${device.$small} {
                    top: 48px;
                }
            }
            #roles-panel {
                text-align: center;
            }
            .menu-item {
                font-size: 24px;
                line-height: 35px;
                font-weight: 900;
                text-transform: uppercase;
                margin-bottom: 12px;
                &--first {
                    margin-top: auto;
                }
                &--last {
                    margin-bottom: auto;
                }
                &.roles {
                    cursor: pointer;
                    margin-bottom: 24px;
                    position: relative;
                }
                &__sub-item {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 35px;
                    margin-bottom: 6px;
                    display: block;
                    &:last-child {
                        padding-bottom: 24px;
                        margin-bottom: 24px;
                        border-bottom: 1px solid #DEC9C9;
                    }
                }
            }
            .caret {
                position: absolute;
                right: -42px;
                bottom: 14px;   
                width: 14px;
                height: 8px;
                margin-right: 16px;
                background-repeat: no-repeat;
                background-size: cover;
                background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 1L7 7L1 1' stroke='%23493838' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                transition: .2s all ease-in-out;
                &.active {
                    transform: rotate(180deg);
                }
            }
        }

        
        .mask {
            transition: .2s all ease-in-out;
            position: absolute;
            top: 0;
            right: 0;
            width: 100vw;
            height: 100vh;
            backdrop-filter: blur(8px);
            z-index: 105;
        }
`



class SideMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            height: 0,
            isMobileMenuActive: false,
            holderMenuName: 'mobile-menu--holder',
            rolesArray: [],
            everythingURL: ''
        };
    }

    componentDidMount() {
        this.createRolesElements();
        this.createEverythingUrl();
    }

    handleClick = () => {
        this.setState({
            isActive: !this.state.isActive
        })
    }

    toggleRoleHeight = () => {
        const { height } = this.state;
 
        this.setState({
            height: height === 0 ? 'auto' : 0,
            holderMenuName: height === 0 ? 'mobile-menu--holder__active' : 'mobile-menu--holder'
        });
    }

    toggleMobileMenu = () => {
        this.setState({ isMobileMenuActive: !this.state.isMobileMenuActive })
    }

    emojiOne = (input) => {
        if (input == "🧤") {
            return emoji(input, {
                baseUrl: '//cdn.jsdelivr.net/npm/emojione-assets@4.5.0/png/64',
                size: ''
            })
        }
        else {
            return input
        }
        
    }

    createRolesElements = () => {
        const roles = [];
        this.props.roles.forEach((role, key, arr) => {

            const url = `${window.location.origin}/galleries?filter=${role.name}`
            if (Object.is(arr.length -1, key)) {
                roles.push(
                    <p key={role.name} className="menu-item__sub-item">
                        <a href={url} >{role.name}</a>
                    </p>)
            }
            else {
                roles.push(<p key={role.name} className="menu-item__sub-item"><a href={url}>{role.name}</a></p>)
            }
            
        });
        this.setState({ rolesArray: roles })
    }

    createEverythingUrl = () => {
        this.setState({ everythingURL: `${window.location.origin}/galleries` });
    }
    


    render() {

        const siteSettings = this.props.siteSettings;

        const sidebarClassName = this.state.isActive ? 'sidebar sidebar__active' : 'sidebar sidebar__inactive';
        const mobileMenuClassName = this.state.isMobileMenuActive ? 'mobile-menu mobile-menu__active' : 'mobile-menu';
        const caretClassName = this.state.height == 0 ? 'caret' : 'caret active';

        const desktopButtonTop = this.props.siteSettings.marquee_text ? '48px' : '24px';
        const mobileButtonTop = this.props.siteSettings.marquee_text ? '54px' : '0'; //marquee height + header top padding

        const hoverGradient = `-webkit-linear-gradient(1turn, ${this.props.siteSettings.hover_gradient_one.hex}, ${this.props.siteSettings.hover_gradient_two.hex}, ${this.props.siteSettings.hover_gradient_three.hex});`
        // console.log('MENU', this.props)
        // console.log('STATE HEIGHT', this.state.height)

        const menuBGColor = this.props.siteSettings.menu_colour.hex;
        const menuBGColorDark = LightenDarkenColor(this.props.siteSettings.menu_colour.hex, -40);
        const menuTextColor = this.props.siteSettings.menu_text_colour.hex;
        const emojiArray = [];

        const menuPanelStyle = {
            backgroundColor: this.state.isActive ? menuBGColor : 'transparent',
            color: menuTextColor
        }

        const mimiCastingMenuItem = this.props.siteSettings.mimi_casting_url ? <a href={ this.props.siteSettings.mimi_casting_url } target="_blank" rel="noopener" className="menu-item mimi-casting">Mimi Casting</a> : '';
        const shopMenuItem = this.props.siteSettings.shop_url ? <a href={ this.props.siteSettings.shop_url } target="_blank" rel="noopener" className="menu-item shop">SHOP</a> : '';

        

        if (siteSettings.menu_emoji_one_url) {
            emojiArray.push(<a 
                                href={siteSettings.menu_emoji_one_url} 
                                target="_blank" 
                                rel="noopener"
                                className="menu-emoji" 
                                key={siteSettings.menu_emoji_one_url + '01'}
                            >
                                {this.emojiOne(siteSettings.menu_emoji_one)}
                                
                            </a>)
        }
        if (siteSettings.menu_emoji_two_url) {
            emojiArray.push(<a 
                                href={siteSettings.menu_emoji_two_url} 
                                target="_blank" 
                                rel="noopener"
                                className="menu-emoji" 
                                key={siteSettings.menu_emoji_two_url + '02'}
                            >
                                {this.emojiOne(siteSettings.menu_emoji_two)}
                            </a>)
        }
        if (siteSettings.menu_emoji_three_url) {
            emojiArray.push(<a 
                                href={siteSettings.menu_emoji_three_url} 
                                target="_blank" 
                                rel="noopener"
                                className="menu-emoji" 
                                key={siteSettings.menu_emoji_three_url + '03'}
                            >
                                {this.emojiOne(siteSettings.menu_emoji_three)}
                            </a>)
        }


        let mask
        if (this.state.isActive) {
            mask = <div className="mask" onClick={this.handleClick}></div>
        }


        return (
            <MenuStyles siteSettings={this.props.siteSettings}>
                <div className={sidebarClassName} style={menuPanelStyle}>
                    <div onClick={this.handleClick} className="menu-button" style={{ top: desktopButtonTop }}></div>
                    <div className="menu-wrapper">
                        <div className="">
                            <p><a href={this.state.everythingURL} className="menu-item">Immy's World</a></p>
                            
                            {/* <div className="menu-item roles" onClick={this.toggleRoleHeight} aria-controls="roles-panel">
                                <div className={caretClassName}></div>
                                FILTER
                            </div> */}
                            { this.state.rolesArray }
                            <p><a href="/galleries" className="menu-item__sub-item">Everything!</a></p>
                            { mimiCastingMenuItem }
                            
                            {/* <AnimateHeight
                                id='roles-panel'
                                duration={ 300 }
                                height={ this.state.height }
                            >
                                {roles}
                            </AnimateHeight> */}
                        </div>
                        <div className="">
                            <p><Link to="/info" className="menu-item">Info</Link></p>
                            { shopMenuItem }
                            {
                                this.props.siteSettings.instagram_url &&
                                <a href={ this.props.siteSettings.instagram_url } target="_blank" rel="noopener" className="instagram-icon"></a>
                            }
                            
                            <div className="menu-emojis">
                                {emojiArray}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mobile-menu-button" onClick={this.toggleMobileMenu} style={{ marginTop: mobileButtonTop }}>

                </div>
                <div className={mobileMenuClassName}>
                    <div className={this.state.holderMenuName}>
                        <div className="close-mobile-menu" onClick={this.toggleMobileMenu}></div>
                        <Link to="/" className="menu-item menu-item--first">IMMY'S WORLD</Link>
                        <div className="menu-item roles" onClick={this.toggleRoleHeight} aria-controls="roles-panel">
                            FILTER
                            <div className={caretClassName}></div>
                        </div>
                        <AnimateHeight
                            id='roles-panel'
                            duration={ 300 }
                            height={ this.state.height }
                        >
                            {this.state.rolesArray}
                            <p className="menu-item__sub-item"><a href={this.state.everythingURL}>Everything!</a></p>
                        </AnimateHeight>
                        { mimiCastingMenuItem }
                        <Link to="/" className="menu-item">Shop</Link>
                        <Link to="/info" className="menu-item">Info</Link>
                        <div className="menu-emojis menu-item--last">
                            {emojiArray}
                        </div>
                    </div>
                </div>
                { mask }
            </MenuStyles>
        )
    }
}

export default SideMenu

