import Marquee, { Motion, randomIntFromInterval } from "react-marquee-slider";
import times from "lodash/times";
import React from 'react';
import styled from 'styled-components';
import {device} from '../styles/Breakpoints'

function MarqueeContent(props) {
    return (
    <div>{props.content}</div>
    )
}

const MarqueeStyles = styled.div`
        height: var(--marquee-height);
        position: sticky; 
        top: 0;
        z-index: 5;
        > div {
            > div {
                @media ${device.$small} {
                    animation-duration: 24s !important;
                }
            }
            
        }
        
    `


export default function MyMarquee({color, content}) {
    const rgbColour = `rgba(${color?.r}, ${color?.g}, ${color?.b}, ${color?.a})`;
    const bgStyle = {
        backgroundColor: rgbColour
    }
    if (content) {
        return (
            <MarqueeStyles className="marquee" style={ bgStyle }>
                <Marquee velocity={30} minScale={0.7} resetAfterTries={200}>
                    {times(10, Number).map((id) => (
                        <div
                        key={id}
                        style={{
                            width: "auto",
                            height: "100%",
                            borderRadius: "50%",
                            textAlign: "center",
                            lineHeight: "30px",
                            marginRight: "24px",
                            padding: "0 12px",
                        }}
                        >
                            <MarqueeContent content={content}/>
                        </div>
                    ))}
                </Marquee>
                </MarqueeStyles>
        )
    }
    else {
        return (
            <></>
        )
    }
    
}